//variables
var navbarHeight = $(".navbar").outerHeight(true);
var docHeight = $(window).height();
if ($('.footer').length) {
	var footerHeight = $('.footer').height();
	var footerTop = $('.footer').position().top + footerHeight;
}
//functions

function myaffix(elem) {
	var element = $('#'+elem);
	var elementWrap = $('.'+elem+'-wrapper');
    var affixoffset = element.offset().top - $('body').css('margin-top');

    elementWrap.height(element.outerHeight(true));
};

//onLoad
$(function() {
	//navbar fixed to top
	myaffix('header-navbar');

	//foter fixed to bottom
	if ($('.footer').length && footerTop < docHeight) {
		$('.footer').css('margin-top', (docHeight - footerTop - navbarHeight) + 'px');
	}

	//navbar-collapse fix
	$(".navbar-collapse").css({ maxHeight: docHeight - $(".navbar-header").height() + "px" });

});

//onResize
$( window ).resize(function() {
	//navbar fixed to top
	myaffix('header-navbar');
	if(docHeight != $(window).height()) {
		docHeight = $(window).height();

		//foter fixed to bottom	
		footerHeight = $('.footer').height();
		footerTop = $('.footer').position().top + footerHeight;
		if (footerTop < docHeight) {
			$('.footer').css('margin-top', (docHeight - footerTop) + 'px');
		}

		//navbar-collapse fix
		$(".navbar-collapse").css({ maxHeight: docHeight - $(".navbar-header").height() + "px" });			
	}
	if ($(document).height() > $(window).height() && $('.navbar-collapse').attr('aria-expanded') == "false") {
	    //$("body").removeClass('no-scroll-no-sb').removeClass('no-scroll');
	}
});

//onEvents

//navbar open body scroll fix
$('.navbar-collapse').on('show.bs.collapse', function () {
	if ($(document).height() > $(window).height() || $("body").hasClass('no-scroll')) {
	  var scp = $("body").scrollTop();
	  //$("body").addClass('no-scroll').removeClass('no-scroll-no-sb');
      //$("body").css('margin-top', -scp);

	} else {
		//$("body").addClass('no-scroll-no-sb').removeClass('no-scroll');
	}
})

$('.navbar-collapse').on('hide.bs.collapse', function () {
    //$("body").removeClass('no-scroll').removeClass('no-scroll-no-sb');
	var bodyMargin = parseFloat($("body").css('margin-top'));
    //$("body").css('margin-top', 0);
    //$("body").scrollTop(-1* bodyMargin);
})

$('.navbar-collapse').on('hidden.bs.collapse', function () {
	//navbar fixed to top
	myaffix('header-navbar');
})

function getLessVariableValue(variable) {
    var elt = document.createElement('div');
    elt.className = "variable-"+variable;
    elt.style.display= "none";        // ensure element is not visible
    document.body.appendChild(elt);   // elt must be in DOM to compute styles
    var result = window.getComputedStyle(elt, ':before').getPropertyValue('content');
    document.body.removeChild(elt);
    return result;
}