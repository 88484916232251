//variables
//var variable = $(".element").funkce();

function showError(inputId) {
    if($(inputId).prop('tagName') != 'SELECT') {
        $(inputId).addClass('error');
    } else {
        $(inputId).parent().addClass('error');
    }
    $(inputId).parents('.form-group').find('.errorlog').show();
    errorChecker();
}

function hideError(inputId) {
    if($(inputId).prop('tagName') != 'SELECT') {
        $(inputId).removeClass('error');
    } else {
        $(inputId).parent().removeClass('error');
    }
    $(inputId).parents('.form-group').find('.errorlog').hide();
    errorChecker();
}

function errorChecker() {
    if($('.reservation-form').find('.error').length !== 0) {
        $('.reservation-form__btn-wrapper .btn').attr('disabled', '');
    } else {
        if($('.reservation-form [required]').filter(function () {return $(this).val().length === 0;}).length === 0) {
            $('.reservation-form__btn-wrapper .btn').removeAttr('disabled');
        }
    }
}

function days_between(insertdate1, insertdate2) {
    var date1 = insertdate1.split('.');
    var date2 = insertdate2.split('.');
    var StartDate = new Date(date1[2], date1[1], date1[0]);
    var EndDate = new Date(date2[2], date2[1], date2[0]);

    var nDays = (Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate()) -
                 Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate())) / 86400000;

    return nDays + 1;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

$('#termin-od').datepicker({
    language: "cs",
    orientation: "bottom auto",
    daysOfWeekDisabled: [1,2,4,5,6],
    defaultViewDate: { year: (new Date()).getFullYear(), month: 05, day: 1 },
    startDate: new Date(),
    keepEmptyValues: true
})
$('#termin-do').datepicker({
    language: "cs",
    orientation: "bottom auto",
    daysOfWeekDisabled: [1,2,4,5,6],
    defaultViewDate: { year: (new Date()).getFullYear(), month: 05, day: 1 },
    startDate: new Date(),
    keepEmptyValues: true
})

$('#termin-od').on('changeDate', function(){
    var terminOdDate = $('#termin-od').datepicker('getDate');
    var terminDoStartDate = addDays(terminOdDate, 5);
    $('#termin-do').datepicker('setStartDate', terminDoStartDate);
});

$('#termin-od, #termin-do').on('changeDate', function(){
    if($('#termin-od').val() != "" && $('#termin-do').val() != "") {
        if(days_between($('#termin-od').val(), $('#termin-do').val()) >= 7) {
            hideError('#termin-do');
        } else {
            showError('#termin-do');
        }
    }
});

$('.reservation-form [required]').on('input propertychange', function(){
    return !this.value ? showError('#'+$(this).attr('id')) : hideError('#'+$(this).attr('id'));
});

$('.reservation-form').on('submit', function(e) {
    /*e.preventDefault();
    if ($('#podminky').is(":checked")) {
        $(this)[0].submit();
    } else {
        alert('Je potřeba souhlasit s obchodními podmínkami');
    }*/
});

/*$('.kalendar-apartman-levy').datepicker({
    maxViewMode: 1,
    language: "cs",
    keyboardNavigation: false,
    forceParse: false,
    datesDisabled: ['04/06/2018', '05/06/2018', '06/06/2018', '07/06/2018', '08/06/2018', '09/06/2018', '10/06/2018', '20/06/2018', '21/06/2018', '22/06/2018', '23/06/2018', '24/06/2018', '25/06/2018', '01/06/2018', '28/06/2018', '29/06/2018', '30/06/2018', '01/07/2018', '08/07/2018'],
    defaultViewDate: { year: (new Date()).getFullYear(), month: (new Date()).getMonth(), day: (new Date()).getDay() }
});

$('.kalendar-apartman-pravy').datepicker({
    maxViewMode: 1,
    language: "cs",
    keyboardNavigation: false,
    forceParse: false,
    datesDisabled: ['04/06/2018', '05/06/2018', '06/06/2018', '07/06/2018', '08/06/2018', '09/06/2018', '10/06/2018', '20/06/2018', '21/06/2018', '22/06/2018', '23/06/2018', '24/06/2018', '25/06/2018', '01/06/2018', '28/06/2018', '29/06/2018', '30/06/2018', '01/07/2018', '08/07/2018'],
    defaultViewDate: { year: (new Date()).getFullYear(), month: (new Date()).getMonth(), day: (new Date()).getDay() }
});*/
//rezervace
$(function() {
    vyberapartmanu(0);
    $('#vyber-apartmanu').change(function(){
        vyberapartmanu($(this).val());
    });
});
function vyberapartmanu(val) {
    $('#apartman-levy-form, #apartman-pravy-form').hide(); 
    $('#apartman-levy-form, #apartman-pravy-form, .apartman-calendars').find('input, select, checkbox, span.form-control').attr('disabled', ''); 
    switch (val) {
        case 'apartman-levy':
            $('#apartman-levy-form').show();
            $('#apartman-levy-form, .apartman-calendars').find('input, select, checkbox, span.form-control').removeAttr('disabled'); 
            break;
        case 'apartman-pravy':
            $('#apartman-pravy-form').show();
            $('#apartman-pravy-form, .apartman-calendars').find('input, select, checkbox, span.form-control').removeAttr('disabled'); 
            break;
        case 'apartman-levy-pravy':
            $('#apartman-levy-form, #apartman-pravy-form').show();
            $('#apartman-levy-form, #apartman-pravy-form, .apartman-calendars').find('input, select, checkbox, span.form-control').removeAttr('disabled'); 
    }
}

//onLoad
$(function() {
	$("#main-carousel").owlCarousel({
	  items: 1,
	  loop: true,
	  nav: false,
	  dots: false,
	  autoplay: true,
	  autoplayTimeout: 4000,
	  autoplaySpeed: 1800,
	  autoplayHoverPause: false,
      animateIn: 'fadeIn',
      animateOut: 'fadeOut'
	});
});

//onResize
$( window ).resize(function() {

});

$(".scrollBottom").on("click", function(){
	$(window).scrollTo($(this).parent().next(".section"), 800, {
		offset:-$(".header-navbar-wrapper").outerHeight(true)
	});
});

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();   


    $('body').scrollspy({ target: '#header-navbar', offset: $(".navbar").outerHeight(true) + 1 });
    $('a.nav-scroll').on('click', function(e){
        e.preventDefault();
        var link = $(this);
        var target = $(link.attr('href'));
        history.pushState("", "", link.attr('href'));

        try {
            history.pushState("", "", link.attr('href'));
        }
        catch(err) {
            window.location.hash = link.attr('href');
        }

        $(window).scrollTo(target, 800, {
            offset:-$(".header-navbar-wrapper").outerHeight(true)
        });

        if($('.navbar-toggle').is(':visible') && $('.navbar-nav').is(':visible')) {
            $('.navbar-toggle').click();
        }

    })
});